import React, { useState } from "react";
import Helmet from "react-helmet";
import { BlackBoxText } from "components/BlackBoxText";
import { Content } from "components/Content";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import { Page } from "components/Page";
import { useIsCompact } from "hooks/useIsCompact";

export const query = graphql`
  {
    allSitePage(sort: { fields: [context___name], order: ASC }) {
      nodes {
        path
        context {
          id
          name
          description
          scriptString
        }
      }
    }
  }
`;

function SocietiesPage(p) {
  const isCompact = useIsCompact(p.location.search);

  const [query, setQuery] = useState("");

  const rawPages = p.data.allSitePage.nodes;

  const allEventsPage = rawPages.find(
    (page) => page.context.id === "_ALLEVENTS"
  );

  const pages = [
    allEventsPage,
    ...rawPages.filter((page) => page.context.id !== "_ALLEVENTS"),
  ];

  const societyPathRegex = new RegExp(/^\/society\//, "i");
  const societies = pages.filter((page) => !!societyPathRegex.test(page.path));

  let filteredSocieties = societies;

  if (query) {
    const alphaNumericRegex = new RegExp(/\W/g);
    const testQuery = query.replace(alphaNumericRegex, "").toLowerCase();

    filteredSocieties = societies.filter((society) => {
      const testName = society.context.name
        .replace(alphaNumericRegex, "")
        .toLowerCase();

      return testName.includes(testQuery);
    });
  }

  return (
    <Page light={false} fixed={false} locationSearch={p.location.search}>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"Societies | Exeter Students' Guild Events"}
        meta={[
          {
            name: `description`,
            content: "See all events for each Exexter Student's society",
          },
          {
            property: `og:url`,
            content: `https://events.exeterguild.com${p.location.pathname}`,
          },
          {
            property: `og:title`,
            content: "Societies | Exeter Students' Guild Events",
          },
          {
            property: `og:description`,
            content: ``,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Content>
        <BlackBoxText align="center">SOCIETIES</BlackBoxText>
        <div className="societies-input-box">
          <img src="/iconSearch.svg" />
          <input
            value={query}
            onChange={(e) => setQuery(e.target.value || "")}
            placeholder="Search"
            type="search"
          />
        </div>
        <div className="societies-grid">
          {(() => {
            let baseIndex = 0;

            return filteredSocieties.map((society, i) => {
              let color = "dark";

              if (baseIndex === 1) {
                color = "lime";
              } else if (baseIndex === 2) {
                color = "pink";
              }

              baseIndex++;

              if (baseIndex > 2) {
                baseIndex = 0;
              }

              return (
                <Society
                  key={society.path}
                  society={society}
                  color={color}
                  onClick={
                    isCompact
                      ? ({ description, scriptString }) => {
                          const parser = new DOMParser();
                          const script = parser
                            .parseFromString(scriptString, "text/html")
                            .getElementsByTagName("script")[0];
                          const id = script.getAttribute("data-fixr-shop-id");

                          window.postMessage({
                            message: "fixr.wl:viewTicketshop",
                            id,
                            description: description,
                          });
                        }
                      : undefined
                  }
                />
              );
            });
          })()}
        </div>
      </Content>
    </Page>
  );
}

function Society({ society, color, onClick }) {
  const societyClassName = `society-card society-card--${color}`;

  const LinkWrapper = ({ children }) => (
    <Link to={society.path} className={societyClassName}>
      {children}
    </Link>
  );

  // If we are compact, an onclick handler gets passed in so we should yield the
  // app ticket shop id for the post message
  const DivWrapper = ({ children }) => (
    <div
      className={societyClassName}
      onClick={onClick ? () => onClick(society.context) : undefined}
    >
      {children}
    </div>
  );

  const Wrapper = onClick ? DivWrapper : LinkWrapper;

  return (
    <Wrapper>
      <div className="society-card__content">
        <h3>{society.context.name}</h3>
      </div>
    </Wrapper>
  );
}

export default SocietiesPage;
